import { ITokenConsumer } from '../models/TokenUser';
import { BaseService } from './BaseService';

export class TokenConsumerService extends BaseService {
    public getConsumerAllocation = async (
        accessToken: string,
        tokenConsumer: ITokenConsumer,
    ): Promise<ITokenConsumer> => {
        const result = await this.getResponseAsync<ITokenConsumer>(
            {
                commandPath: `tokenConsumers`,
                method: 'POST',
                body: tokenConsumer,
            },
            accessToken,
        );
        return result;
    };
}
