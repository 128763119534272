import { PayloadAction, Slice, createSlice } from '@reduxjs/toolkit';
import { ITokenConsumer } from '../../../libs/models/TokenUser';
import { AdminAppState, initialState } from './AdminAppState';

export const adminAppSlice: Slice<AdminAppState> = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        allocateTokens: (state: AdminAppState, action: PayloadAction<number>) => {
            state.tokenPoolNumber = state.tokenPoolNumber + action.payload;
            state.tokensAllocated = state.tokensAllocated - action.payload;
        },
        setTokenConsumers: (state: AdminAppState, action: PayloadAction<ITokenConsumer[]>) => {
            state.users = action.payload;
        },
        setTokenPool: (state: AdminAppState, action: PayloadAction<number>) => {
            state.tokenPoolNumber = action.payload;
        },
        setEditedBudgetUser: (state: AdminAppState, action: PayloadAction<ITokenConsumer>) => {
            state.editedBudgetUser = action.payload;
        },
        setEditedTokenUser: (state: AdminAppState, action: PayloadAction<ITokenConsumer>) => {
            state.editedTokensUser = action.payload;
        },
        setInitialTokenPool: (state: AdminAppState, action: PayloadAction<number>) => {
            state.tokensInitialPool = action.payload;
        },
        setAllocatedTokens: (state: AdminAppState, action: PayloadAction<number>) => {
            state.tokensAllocated = action.payload;
        },
        setActualTokenUsage: (state: AdminAppState, action: PayloadAction<number>) => {
            state.tokensActualUsage = action.payload;
        },
        setPricePerToken: (state: AdminAppState, action: PayloadAction<number>) => {
            state.pricePerToken = action.payload;
        },
    },
});

export const {
    allocateTokens,
    returnTokensToPool,
    setTokenConsumers,
    setTokenPool,
    setEditedBudgetUser,
    setEditedTokenUser,
    setInitialTokenPool,
    setAllocatedTokens,
    setPricePerToken,
    setActualTokenUsage,
} = adminAppSlice.actions;
export default adminAppSlice.reducer;
