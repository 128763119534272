import { Button, Input, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { ArrowUndo28Regular, Save28Regular } from '@fluentui/react-icons';
import { FC, useState } from 'react';
import { useAdmin } from '../../libs/hooks/useAdmin';
import { ITokenConsumer } from '../../libs/models/TokenUser';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { allocateTokens, setEditedTokenUser } from '../../redux/features/admin/adminAppSlice';
import { setBudgetToConsumer } from '../../redux/features/admin/token-consumers/TokenConsumerSlice';

interface EditableNumberInputProps {
    value: string;
    user: ITokenConsumer;
}

const useClasses = makeStyles({
    numberInput: {
        ...shorthands.padding(tokens.spacingHorizontalS),
        ...shorthands.margin(tokens.spacingHorizontalM),
    },
});

export const EditableNumberInput: FC<EditableNumberInputProps> = (props: EditableNumberInputProps) => {
    const dispatch = useAppDispatch();
    const num = Math.round(parseInt(props.value)).toString();
    const [numberInputValue, setNumberInputValue] = useState<string>(num);
    const { tokenPoolNumber, pricePerToken } = useAppSelector((state: RootState) => state.admin);
    const { tokensForUser, budgetForUser } = useAppSelector((state: RootState) => state.tokenConsumers[props.user.id]);
    const admin = useAdmin();
    const classes = useClasses();

    const handleNumberInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = parseInt(event.target.value, 10) * 1000; // Explicitly specify radix
        const maxValue = tokenPoolNumber / pricePerToken + tokensForUser / 1000;

        // Handle NaN scenario
        if (isNaN(inputValue)) {
            setNumberInputValue(Math.round(props.user.budgetUsage / pricePerToken).toString());
            return;
        }

        // Handle boundary conditions
        if (inputValue / 1000 > maxValue) {
            setNumberInputValue(maxValue.toString());
        } else if (inputValue < 0) {
            setNumberInputValue(Math.round(props.user.budgetUsage / pricePerToken).toString());
        } else {
            setNumberInputValue(Math.round(inputValue / 1000).toString());
        }
    };

    const handleSave = async () => {
        if (parseInt(numberInputValue) < props.user.budgetUsage / pricePerToken) {
            dispatch(allocateTokens(budgetForUser - props.user.budgetUsage));
            dispatch(
                setBudgetToConsumer({
                    userId: props.user.id,
                    count: {
                        budgetForUser: props.user.budgetAllocation,
                        tokensForUser: Math.round(props.user.budgetAllocation / (pricePerToken / 1000)),
                    },
                }),
            );
            await admin.allocateBudget(props.user.id, props.user.budgetUsage);
            dispatch(setEditedTokenUser(undefined));
        } else {
            dispatch(allocateTokens(budgetForUser - parseInt(numberInputValue) * pricePerToken));
            dispatch(
                setBudgetToConsumer({
                    userId: props.user.id,
                    count: {
                        tokensForUser: parseInt(numberInputValue) * 1000,
                        budgetForUser: parseInt(numberInputValue) * pricePerToken,
                    },
                }),
            );
            dispatch(setEditedTokenUser(undefined));
            await admin.allocateBudget(props.user.id, parseInt(numberInputValue) * pricePerToken);
        }
    };

    const handleCancel = () => {
        dispatch(setEditedTokenUser(undefined));
    };

    return (
        <>
            <Button size="small" appearance="transparent" icon={<ArrowUndo28Regular />} onClick={handleCancel} />
            <Input
                className={classes.numberInput}
                type="number"
                min={5}
                pattern="[0-9]*"
                step={5}
                max={tokenPoolNumber + tokensForUser}
                value={numberInputValue}
                onChange={handleNumberInputChange}
                contentAfter={'k'}
            />
            <Button size="small" appearance="transparent" icon={<Save28Regular />} onClick={() => void handleSave()} />
        </>
    );
};
