import { Button, Spinner, Textarea, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import { DocumentArrowUp20Regular, Send24Filled, Send24Regular } from '@fluentui/react-icons';
import { log } from 'debug';
import React, { useRef } from 'react';
import { GetResponseOptions } from '../../libs/hooks/useChat';
// import { AlertType } from '../../libs/models/AlertType';
import { ChatMessageType } from '../../libs/models/ChatMessage';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
// import { addAlert } from '../../redux/features/app/appSlice';
import {
    editConversationInput,
    updateBotResponseStatus,
    updateUserIsTyping,
} from '../../redux/features/conversations/conversationsSlice';
//import { Breakpoints } from '../../styles';
import { Constants } from '../../Constants';
import { useFile } from '../../libs/hooks';
import { Breakpoints, customTokens } from '../../styles';
import { TemperatureType } from '../../libs/models/TemperatureType';

interface IInputWithSend {
    isDraggingOver?: boolean;
    onSubmit: (options: GetResponseOptions) => Promise<void>;
    bgColor: string;
}

const useClasses = makeStyles({
    root: {
        // display: 'flex',
        // flexDirection: 'column',
        // ...shorthands.margin(tokens.spacingHorizontalM),
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        maxWidth: '105em',
        // ...shorthands.padding('12px', '0', '36px', '12px'),
        // ...shorthands.margin('0', '62px'),
        '@media (min-width: 1800px)': {
            // ...shorthands.padding('12px', '12px', '36px', '12px'),
        },
    },
    content: {
        // ...shorthands.gap(tokens.spacingHorizontalS),
        // ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalM),
        // display: 'flex',
        // // paddingRight: '50px',
        // flexDirection: 'row',
        // height: '55px',
        // justifyContent: 'center',
        // alignItems: 'center',
        // alignContent: 'center',
        // textAlign: 'center',
        // ...Breakpoints.small({
        //     paddingRight: '0px',
        //     paddingLeft: '0px',
        // }),
        ...shorthands.gap(tokens.spacingHorizontalM),
        display: 'flex',

        flexDirection: 'row',
        width: '100%',
        position: 'relative',
    },
    input: {
        // ...shorthands.margin(tokens.spacingHorizontalS),
        // width: '80%',
        // height: '100%',
        // ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        // ...Breakpoints.small({
        //     width: '95%',
        // }),
        width: '100%',
    },
    precise: {
        '&::after': {
            borderBottomColor: customTokens.precise,
        },
    },
    balanced: {
        '&::after': {
            borderBottomColor: customTokens.balanced,
        },
    },
    creative: {
        '&::after': {
            borderBottomColor: customTokens.creative,
        },
    },
    textarea: {
        // width: '50%',
        maxHeight: '85px',
        // alignItems: 'center',
        // justifyContent: 'center',
        // alignContent: 'center',
        // ...shorthands.padding(tokens.spacingHorizontalM),
        maxWidth: '96%',

        //flexGrow: 1,
    },
    controls: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    typingIndicator: {
        maxHeight: '28px',
    },
    dragAndDrop: {
        ...shorthands.border(tokens.strokeWidthThick, ' solid', tokens.colorBrandStroke1),
        ...shorthands.padding('8px'),
        textAlign: 'center',
        backgroundColor: tokens.colorNeutralBackgroundInvertedDisabled,
        fontSize: tokens.fontSizeBase300,
        color: tokens.colorBrandForeground1,
        caretColor: 'transparent',
    },
    button: {
        // marginLeft: '-50px',
        // position: 'relative',
        // height: '25px',
        display: 'flex',
        flexDirection: 'row',
        // align to right
        position: 'absolute',
        top: '25%',
        right: '1rem',
    },
    uploadButton: {
        // width: 'fit-content',
        // marginLeft: '20.3%',
        // marginRight: '1rem',
        // alignSelf: 'start',
        // justifySelf: 'start',
        // ...Breakpoints.small({
        //     alignSelf: 'self-start',
        //     marginLeft: '10%',
        // }),
        width: 'fit-content',
        alignSelf: 'start',
        justifySelf: 'start',

        marginRight: '1rem',
    },
    uploadButtonWrapper: {
        // width: '50%',
        // display: 'flex',
        // ...shorthands.margin(0, 0, 0, '16px'),
        // // ...shorthands.padding(0, '16px'),
        // ...Breakpoints.small({
        //     ...shorthands.margin(0),
        // }),
        width: 'content-fit',
        display: 'flex',
        marginBottom: '0.5rem',
    },
    contentWrapper: {
        width: '100%',
    },
    tokenInfo: {
        // paddingLeft: '10px',
        // marginLeft: '13%',
        fontWeight: 'bold',
        // ...shorthands.flex('0 0 auto'),
        // textLeft: 'right',
        // textAlign: 'left',
        color: '#353750',
        '@media(min-width: 768px)': {},

        width: '100%',
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'center',

        //paddingLeft: '18px',
        ...Breakpoints.small({
            marginLeft: '0',
            paddingLeft: '0',
        }),

        '& p': {
            width: '100%',

            // ...shorthands.padding('0', '11.3%'),
            // ...shorthands.margin('0', '12px'),
            ...Breakpoints.small({
                // paddingLeft: '5%',
            }),
        },
    },
});

export const InputWithSend: React.FC<IInputWithSend> = ({ isDraggingOver, onSubmit, bgColor }) => {
    const classes = useClasses();
    const [value, setValue] = React.useState('');
    const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const dispatch = useAppDispatch();
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const percentUsed = Math.round(((activeUserInfo?.budgetUsed ?? 1) / (activeUserInfo?.budgetAllocated ?? 1)) * 100);
    const localDocumentFileRef = useRef<HTMLInputElement | null>(null);
    const fileHandler = useFile();
    const temperature: TemperatureType = conversations[selectedId].temperature ?? TemperatureType.Balanced;

    const { importingDocuments } = conversations[selectedId];

    React.useEffect(() => {
        const chatState = conversations[selectedId];
        setValue(chatState.input);
    }, [conversations, selectedId, percentUsed]);

    const message = conversations[selectedId].botResponseStatus;
    const handleSubmit = (value: string, messageType: ChatMessageType = ChatMessageType.Message) => {
        const temperature = conversations[selectedId].temperature;
        if (value.trim() === '') {
            return; // only submit if value is not empty
        }

        setValue('');
        dispatch(editConversationInput({ id: selectedId, newInput: '' }));
        dispatch(updateBotResponseStatus({ chatId: selectedId, status: 'Connecting' }));
        onSubmit({ value, messageType, chatId: selectedId, temperature }).catch((error) => {
            const message = `Error submitting chat input: ${(error as Error).message}`;
            log(message);
            // dispatch(
            //     addAlert({
            //         type: AlertType.Error,
            //         message,
            //     }),
            // );
        });
    };

    const getTemperatureClass = () => {
        switch (temperature) {
            case TemperatureType.Creative:
                return 'creative';
            case TemperatureType.Balanced:
                return 'balanced';
            case TemperatureType.Precise:
                return 'precise';
            default:
                return 'balanced';
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.contentWrapper}>
                <div className={classes.uploadButtonWrapper}>
                    <input
                        type="file"
                        ref={localDocumentFileRef}
                        style={{ display: 'none' }}
                        accept={Constants.app.importTypes}
                        multiple={true}
                        onChange={() => {
                            void fileHandler.handleImport(selectedId, localDocumentFileRef, false);
                        }}
                    />
                    <Button
                        className={classes.uploadButton}
                        icon={<DocumentArrowUp20Regular />}
                        disabled={percentUsed >= 100}
                        appearance="primary"
                        onClick={() => localDocumentFileRef.current?.click()}
                    >
                        Upload Document
                    </Button>
                    {importingDocuments && importingDocuments.length > 0 && <Spinner size="tiny" />}
                </div>

                <div className={classes.content}>
                    <Textarea
                        disabled={percentUsed >= 100}
                        placeholder="Ask me anything..."
                        as="textarea"
                        title="Chat input"
                        aria-label="Chat input field. Click enter to submit input."
                        ref={textAreaRef}
                        id="chat-input"
                        // textarea={{
                        //     className: isDraggingOver
                        //         ? mergeClasses(classes.dragAndDrop, classes.textarea)
                        //         : classes.textarea,
                        // }}
                        textarea={{ className: classes.textarea }}
                        className={mergeClasses(classes.input, classes[getTemperatureClass()])}
                        value={value}
                        // onDrop={handleDrop}
                        onFocus={() => {
                            // update the locally stored value to the current value
                            const chatInput = document.getElementById('chat-input');
                            if (chatInput) {
                                setValue((chatInput as HTMLTextAreaElement).value);
                            }
                            // User is considered typing if the input is in focus

                            activeUserInfo?.id &&
                                dispatch(
                                    updateUserIsTyping({
                                        userId: activeUserInfo.id,
                                        chatId: selectedId,
                                        isTyping: true,
                                    }),
                                );
                        }}
                        onChange={(_event, data) => {
                            if (isDraggingOver) {
                                return;
                            }

                            setValue(data.value);
                            dispatch(editConversationInput({ id: selectedId, newInput: data.value }));
                        }}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter' && !event.shiftKey) {
                                event.preventDefault();
                                if (message == undefined) {
                                    handleSubmit(value);
                                }
                            }
                        }}
                        onBlur={() => {
                            // User is considered not typing if the input is not  in focus
                            activeUserInfo?.id &&
                                dispatch(
                                    updateUserIsTyping({
                                        userId: activeUserInfo.id,
                                        chatId: selectedId,
                                        isTyping: false,
                                    }),
                                );
                        }}
                    />
                    <Button
                        title="Submit"
                        aria-label="Submit message"
                        disabled={value.trim() === '' || message != undefined}
                        className={classes.button}
                        style={{
                            pointerEvents: value.trim() === '' ? 'none' : 'auto',
                            backgroundColor: value.trim() === '' ? 'transparent' : bgColor,
                            color: '#fff',
                        }}
                        size="small"
                        icon={value.trim() === '' ? <Send24Regular /> : <Send24Filled />}
                        onClick={() => {
                            handleSubmit(value);
                        }}
                    />
                </div>
                <div className={classes.tokenInfo}>
                    <p>
                        {Math.round(
                            ((activeUserInfo?.budgetUsed ?? 1) / (activeUserInfo?.budgetAllocated ?? 1)) * 100,
                        ) >= 100
                            ? 'The usage limit has been maximized.'
                            : `${activeUserInfo?.username},
                     you've used ${Math.round(
                         ((activeUserInfo?.budgetUsed ?? 1) / (activeUserInfo?.budgetAllocated ?? 1)) * 100,
                     )}% of your account so far.`}
                    </p>
                </div>
            </div>
        </div>
    );
};
