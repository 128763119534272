import {
    Caption1,
    Card,
    CardFooter,
    CardHeader,
    Text,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import { FC } from 'react';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';

const useStyles = makeStyles({
    main: {
        ...shorthands.gap('80px'),
        ...shorthands.padding('100px', '30px'),
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'center',
        justifyContent: 'center',
    },

    card: {
        width: '400px',
        flexDirection: 'column',
        justifyContent: 'center',
        maxWidth: '100%',
        alignItems: 'center',
        alignContent: 'center',
        height: '200px',
        ...shorthands.padding('80px'),
        textAlign: 'center',
        display: 'flex',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
        ...shorthands.borderRadius('12px'),
    },

    section: {
        width: 'fit-content',
    },

    title: {
        ...shorthands.margin('12px'),
    },

    headerImage: {
        ...shorthands.borderRadius('4px'),
        maxWidth: '44px',
        maxHeight: '44px',
    },

    caption: {
        color: tokens.colorNeutralForeground3,
        fontSize: tokens.fontSizeBase400,
    },

    text: {
        ...shorthands.margin(0),
    },
    cardHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
});

export const TokenConsumerListHeader: FC = () => {
    const { tokenPoolNumber, tokensInitialPool, tokensAllocated, tokensActualUsage, pricePerToken } = useAppSelector(
        (state: RootState) => state.admin,
    );
    const styles = useStyles();

    return (
        <div className={styles.main}>
            <section className={styles.section}>
                <Card
                    // style={{ backgroundColor: '#FAA07D' }}
                    className={styles.card}
                    orientation="horizontal"
                    title="Budget Balance"
                >
                    <CardHeader
                        className={styles.cardHeader}
                        header={
                            <Text weight="semibold" size={900}>
                                {Math.round(tokenPoolNumber).toLocaleString() + '$'}
                            </Text>
                        }
                        description={
                            <Caption1 className={styles.caption} style={{ fontSize: '14px' }}>
                                {'(' +
                                    Math.round(tokenPoolNumber / pricePerToken).toLocaleString() +
                                    'K' +
                                    ' tokens' +
                                    ')'}
                            </Caption1>
                        }
                    />
                    <CardFooter>
                        <Caption1 className={styles.caption}>Budget Balance</Caption1>
                    </CardFooter>
                </Card>
            </section>

            <section className={styles.section}>
                <Card
                    // style={{ backgroundColor: '#7878E8' }}
                    className={styles.card}
                    orientation="horizontal"
                    title="Total Budget"
                >
                    <CardHeader
                        className={styles.cardHeader}
                        header={
                            <Text weight="semibold" size={900}>
                                {Math.round(tokensInitialPool).toLocaleString() + '$'}
                            </Text>
                        }
                        description={
                            <Caption1 className={styles.caption} style={{ fontSize: '14px' }}>
                                {'(' +
                                    Math.round(tokensInitialPool / pricePerToken).toLocaleString() +
                                    'K' +
                                    ' tokens' +
                                    ')'}
                            </Caption1>
                        }
                    />
                    <CardFooter>
                        <Caption1 className={styles.caption}>Total Budget</Caption1>
                    </CardFooter>
                </Card>
            </section>

            <section className={styles.section}>
                <Card
                    // style={{ backgroundColor: '#4747A1' }}
                    className={styles.card}
                    orientation="horizontal"
                    title="Allocated Budget"
                >
                    <CardHeader
                        className={styles.cardHeader}
                        header={
                            <Text weight="semibold" size={900}>
                                {Math.round(tokensAllocated).toLocaleString() + '$'}
                            </Text>
                        }
                        description={
                            <Caption1 className={styles.caption} style={{ fontSize: '14px' }}>
                                {'(' +
                                    Math.round(tokensAllocated / pricePerToken).toLocaleString() +
                                    'K' +
                                    ' tokens' +
                                    ')'}
                            </Caption1>
                        }
                    />
                    <CardFooter>
                        <Caption1 className={styles.caption}>Allocated Budget</Caption1>
                    </CardFooter>
                </Card>
            </section>

            <section className={styles.section}>
                <Card
                    // style={{ backgroundColor: '#7DA0FA' }}
                    className={styles.card}
                    orientation="horizontal"
                    title="Budget Usage"
                >
                    <CardHeader
                        className={styles.cardHeader}
                        header={
                            <Text weight="semibold" size={900}>
                                {tokensActualUsage.toFixed(1).toLocaleString() + '$'}
                            </Text>
                        }
                        description={
                            <Caption1 className={styles.caption} style={{ fontSize: '14px' }}>
                                {'(' +
                                    Math.round(tokensActualUsage / pricePerToken).toLocaleString() +
                                    'K' +
                                    ' tokens' +
                                    ')'}
                            </Caption1>
                        }
                    />
                    <CardFooter>
                        <Caption1 className={styles.caption}>Budget Usage</Caption1>
                    </CardFooter>
                </Card>
            </section>
        </div>
    );
};
