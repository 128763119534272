import { ITokenConsumer } from '../../../libs/models/TokenUser';

export interface AdminAppState {
    users: ITokenConsumer[];
    tokenPoolNumber: number;
    tokensAllocated: number;
    tokensInitialPool: number;
    tokensActualUsage: number;
    editedBudgetUser?: ITokenConsumer;
    editedTokensUser?: ITokenConsumer;
    pricePerToken: number;
}

export const initialState: AdminAppState = {
    users: [],
    tokenPoolNumber: 0,
    tokensAllocated: 0,
    tokensInitialPool: 0,
    tokensActualUsage: 0,
    editedBudgetUser: undefined,
    editedTokensUser: undefined,
    pricePerToken: 0,
};
