import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ITokenConsumer } from '../../../../libs/models/TokenUser';
import { TokenConsumerState, initialState } from '../token-consumers/TokenConsumerState';

export const TokenConsumerSlice = createSlice({
    name: 'tokenConsumers',
    initialState: initialState,
    reducers: {
        setBudgetToConsumer: (
            state: TokenConsumerState,
            action: PayloadAction<{ userId: string; count: { budgetForUser: number; tokensForUser: number } }>,
        ) => {
            const { userId, count } = action.payload;
            state[userId] = count;
        },

        setBudgetToConsumers: (
            state: TokenConsumerState,
            action: PayloadAction<{ consumers: ITokenConsumer[]; pricePerToken: number }>,
        ) => {
            for (const user of action.payload.consumers) {
                state[user.id] = {
                    budgetForUser: user.budgetAllocation,
                    tokensForUser: Math.round(user.budgetAllocation / (action.payload.pricePerToken / 1000)),
                };
            }
        },
    },
});
export const { setBudgetToConsumer, setBudgetToConsumers } = TokenConsumerSlice.actions;
export default TokenConsumerSlice.reducer;
