import { useMsal } from '@azure/msal-react';
import { AuthHelper } from '../auth/AuthHelper';
import { ITokenConsumer } from '../models/TokenUser';
import { TokenConsumerService } from '../services/TokenConsumerService';

export const useToken = () => {
    const tokenConsumerService: TokenConsumerService = new TokenConsumerService(
        process.env.REACT_APP_BACKEND_URI as string,
    );

    const { instance, inProgress } = useMsal();

    const getConsumerAllocation = async (tokenConsumer: ITokenConsumer): Promise<ITokenConsumer> => {
        const accessToken: string = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        const result = await tokenConsumerService.getConsumerAllocation(accessToken, tokenConsumer);
        return result;
    };

    return { getConsumerAllocation };
};
