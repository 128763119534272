import { Button, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { DismissCircle24Filled } from '@fluentui/react-icons';
import { FC, useState } from 'react';
import { Constants } from '../../Constants';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';

const useAlertBackdropStyles = makeStyles({
    backdrop: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        zIndex: 9998,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    alertContainer: {
        display: 'flex',
        ...shorthands.borderRadius(tokens.borderRadiusXLarge),
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '600px',
        height: '200px',
        backgroundColor: '#FFF',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
        zIndex: 9999,
        '@media (max-width: 400px)': {
            width: '60%',
            ...shorthands.padding('15px'),
        },
    },
});

export const AlertBackdrop: FC = () => {
    const classes = useAlertBackdropStyles();
    const [open, setOpen] = useState(true);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);

    const percentageUsed = Math.round(
        ((activeUserInfo?.budgetUsed ?? 1) / (activeUserInfo?.budgetAllocated ?? 1)) * 100,
    );

    if (percentageUsed <= Constants.TOKEN_USAGE_PERCENT_ALERT_THRESHOLD || !open) return null;

    const message = `
        ${activeUserInfo?.username},
        you've used ${percentageUsed}% of your account so far.
    `;

    return (
        <>
            <div className={classes.backdrop}></div>
            <div className={classes.alertContainer}>
                <Button
                    shape="circular"
                    appearance="transparent"
                    icon={<DismissCircle24Filled />}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        color: '#353750',
                        border: 'none !important',
                        outline: 'none !important',
                    }}
                    onClick={() => {
                        setOpen(false);
                    }}
                />
                <div
                    style={{
                        fontWeight: 'bold',
                        fontSize: '17px',
                        textAlign: 'center',
                        color: '#353750',
                        padding: '20px',
                    }}
                >
                    {message}
                </div>
            </div>
        </>
    );
};
