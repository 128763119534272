import { Button, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { Edit24Regular } from '@fluentui/react-icons';
import { ITokenConsumer } from '../../libs/models/TokenUser';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { setEditedBudgetUser, setEditedTokenUser } from '../../redux/features/admin/adminAppSlice';
import { EditableNumberInput } from './EditableNumberInput';

interface EditableNumberCellProps {
    user: ITokenConsumer;
}

const useClasses = makeStyles({
    usageBar: {
        ...shorthands.gap(tokens.spacingHorizontalL),
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'row',
        width: '60%',
    },
});

export const TokenAllocatedCell: React.FC<EditableNumberCellProps> = (props: EditableNumberCellProps) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const { editedTokensUser } = useAppSelector((state: RootState) => state.admin);
    const tokensForUser = useAppSelector(
        (state: RootState) => state.tokenConsumers[props.user.id].tokensForUser / 1000,
    );

    const handleReallocate = (user: ITokenConsumer) => {
        dispatch(setEditedBudgetUser(undefined));
        dispatch(setEditedTokenUser(user));
    };
    return editedTokensUser === props.user ? (
        <EditableNumberInput value={tokensForUser.toString()} user={props.user} />
    ) : (
        <div className={classes.usageBar}>
            {Math.round(tokensForUser).toLocaleString() + 'K'}
            <Button
                title="Edit token allocation"
                aria-label="edit token allocation"
                size="small"
                appearance="transparent"
                icon={<Edit24Regular />}
                onClick={() => {
                    handleReallocate(props.user);
                }}
            />
        </div>
    );
};
