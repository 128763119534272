import {
    DataGrid,
    DataGridBody,
    DataGridCell,
    DataGridHeader,
    DataGridHeaderCell,
    DataGridRow,
    RowRenderer,
} from '@fluentui-contrib/react-data-grid-react-window';
import {
    Avatar,
    Input,
    TableCellLayout,
    TableColumnDefinition,
    createTableColumn,
    makeStyles,
    shorthands,
    tokens,
} from '@fluentui/react-components';
import { Search24Regular } from '@fluentui/react-icons';
import { CSSProperties, FC, ReactNode, useEffect, useState } from 'react';
import { ITokenConsumer } from '../../libs/models/TokenUser';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { BudgetAllocatedCell } from './BudgetAllocatedCell';
import { TokenAllocatedCell } from './TokenAllocatedCell';
import { TokenPercentageUsage } from './TokensPercentageUsage';

interface RenderCellProps {
    renderCell: (item: ITokenConsumer) => ReactNode;
}

interface RenderHeaderCellProps {
    renderHeaderCell: () => ReactNode;
}

const useClasses = makeStyles({
    root: {
        justifyContent: 'center',
        height: '10%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    headerCell: {
        fontWeight: 'bold',
        textAlign: 'right',
        direction: 'ltr',
    },
    list: {
        overflowY: 'auto',
        overflowX: 'hidden',
        '&:hover': {
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: tokens.colorScrollbarOverlay,
                visibility: 'visible',
            },
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorSubtleBackground,
        },
        alignItems: 'stretch',
        ...shorthands.padding(tokens.spacingHorizontalL),
        direction: 'ltr',
    },
    input: {
        ...shorthands.padding(tokens.spacingHorizontalM),
        backgroundColor: tokens.colorSubtleBackground,
        fontSize: tokens.fontSizeBase400,
        width: '30%',
    },
    row: {
        direction: 'ltr',
        textAlign: 'left',
    },
});

export const TokenConsumerList: FC = () => {
    const classes = useClasses();
    const { users } = useAppSelector((state: RootState) => state.admin);
    const [filterText, setFilterText] = useState('');
    const [filteredUsers, setFilteredUsers] = useState<ITokenConsumer[]>(users);

    const handleFilter = (text: string) => {
        const lowercasedText = text.toLowerCase();
        const filtered = users.filter(
            (user) =>
                user.fullName.toLowerCase().includes(lowercasedText) ||
                user.emailAddress.toLowerCase().includes(lowercasedText),
        );
        setFilteredUsers(filtered);
    };

    useEffect(() => {
        handleFilter(filterText);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterText, users]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(event.target.value);
    };

    const columns: Array<TableColumnDefinition<ITokenConsumer>> = [
        createTableColumn<ITokenConsumer>({
            columnId: 'tokensBar',
            compare: (a, b) => {
                return a.budgetUsage - b.budgetUsage;
            },
            renderHeaderCell: () => {
                return 'Token Usage';
            },
            renderCell: (item) => {
                return <TokenPercentageUsage user={item} />;
            },
        }),
        createTableColumn<ITokenConsumer>({
            columnId: 'tokensAllocated',
            compare: (a, b) => {
                return a.budgetAllocation - b.budgetAllocation;
            },
            renderHeaderCell: () => {
                return 'Tokens Allocated';
            },
            renderCell: (item) => {
                return <TokenAllocatedCell user={item} />;
            },
        }),
        createTableColumn<ITokenConsumer>({
            columnId: 'budgetAllocation',
            compare: (a, b) => {
                return a.budgetAllocation - b.budgetAllocation;
            },
            renderHeaderCell: () => {
                return 'Budget Allocation';
            },
            renderCell: (item) => {
                return <BudgetAllocatedCell user={item} />;
            },
        }),
        createTableColumn<ITokenConsumer>({
            columnId: 'emailAddress',
            compare: (a, b) => {
                return a.emailAddress.localeCompare(b.emailAddress);
            },
            renderHeaderCell: () => {
                return 'Email';
            },
            renderCell: (item) => {
                return item.emailAddress;
            },
        }),
        createTableColumn<ITokenConsumer>({
            columnId: 'name',
            compare: (a, b) => {
                return a.fullName.localeCompare(b.fullName);
            },
            renderHeaderCell: () => {
                return 'Name';
            },
            renderCell: (item) => {
                return (
                    <TableCellLayout media={<Avatar aria-label={item.fullName} name={item.fullName} />}>
                        {item.fullName}
                    </TableCellLayout>
                );
            },
        }),
    ];

    const renderRow: RowRenderer<ITokenConsumer> = (
        { item, rowId }: { item: ITokenConsumer; rowId: React.Key },
        style: CSSProperties,
    ) => (
        <DataGridRow<ITokenConsumer> key={rowId as number} style={style}>
            {({ renderCell }: RenderCellProps) => (
                <DataGridCell className={classes.row}>{renderCell(item)}</DataGridCell>
            )}
        </DataGridRow>
    );
    return (
        <>
            <div className={classes.root}>
                <Input
                    title="Search by name/email"
                    placeholder="Search"
                    type="search"
                    autoFocus
                    contentAfter={<Search24Regular />}
                    className={classes.input}
                    value={filterText}
                    onChange={handleInputChange}
                />
            </div>
            <DataGrid items={filteredUsers} columns={columns.reverse()} sortable className={classes.list}>
                <DataGridHeader>
                    <DataGridRow>
                        {({ renderHeaderCell }: RenderHeaderCellProps) => (
                            <DataGridHeaderCell className={classes.headerCell}>{renderHeaderCell()}</DataGridHeaderCell>
                        )}
                    </DataGridRow>
                </DataGridHeader>
                <DataGridBody<ITokenConsumer> itemSize={50} height={450}>
                    {renderRow}
                </DataGridBody>
            </DataGrid>
        </>
    );
};
