import { makeStyles, shorthands } from '@fluentui/react-components';
import { FC } from 'react';
import { TokenConsumerList } from '../admin/TokenConsumerList';
import { TokenConsumerListHeader } from '../admin/TokenConsumerListHeader';

const useClasses = makeStyles({
    container: {
        ...shorthands.overflow('hidden'),
        ...shorthands.gap('8px'),
        alignContent: 'start',
        height: '100%',
        overflowY: 'auto',
        overflowX: 'auto',
    },
});

export const AdminView: FC = () => {
    const classes = useClasses();

    return (
        <div className={classes.container}>
            <TokenConsumerListHeader />
            <TokenConsumerList />
        </div>
    );
};
